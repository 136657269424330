<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu spec-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [isMiniModeWidget]="true"
            (gotoSite)="onLinkClick()"
    >
        <div class="toro-flex-child mini-mode-widget-container toro-component-shield-host">
            <div class="toro-widget-content-pane mini">
                <img src="../../../../../../assets/images/intelli360/intelli360-maint-mini.png" class="prevent-select" alt="">
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu spec-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onLinkClick()"
    >

        <div class="toro-flex-child" id="spec-widget-content" (click)="showDesktopModal = true" style="cursor: pointer;">
            <div *ngIf="displayCols == 1 && displayRows == 1" class="toro-widget-content-pane c1-r1" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <img src="../../../../../../assets/images/intelli360/intelli360-maint-1x1.png" class="prevent-select" alt="">
            </div>

            <div *ngIf="displayCols == 2 && displayRows == 1" class="toro-widget-content-pane c2-r1" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <img src="../../../../../../assets/images/intelli360/intelli360-maint-2x1.png" class="prevent-select" alt="">
            </div>

            <div *ngIf="displayCols == 1 && displayRows == 2" class="toro-widget-content-pane c1-r2" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <img src="../../../../../../assets/images/intelli360/intelli360-maint-1x2.png" class="prevent-select" alt="">
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="i360-title-icon" src="../../../../../../assets/images/intelli360/intelli360-md-logo.png" alt="">
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
<!--    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': (isFreeFormCourse ? WidgetDoubleRowHeight : WidgetSingleRowHeight) + 'px'}">-->
<!--        <ng-container [ngTemplateOutlet]="standardView"></ng-container>-->
<!--    </div>-->
</toro-mini-mode-widget-modal>

<toro-intelli360-modal
        *ngIf="showDesktopModal"
        [isMiniModal]="isGridsterInMobileMode"
        [headerImgSrc]="'../../../../../../assets/images/intelli360/intelli360-md-logo.png'"
        (gotoSite)="onLinkClick()"
        (cancel)="showDesktopModal = false;"
>
    <div id="i360-modal-content-container" [ngClass]="{'mini-modal': isGridsterInMobileMode}">
        <img src="../../../../../../assets/images/intelli360/intelli360-maint-dt-modal.png" class="prevent-select" alt="">
    </div>
</toro-intelli360-modal>

