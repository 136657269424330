<div id="my-gauge-container" class="linear">
    <div id="my-datum-container" class="linear" >
        <div id="my-substance">{{substance.substance}}</div>
        <div class="my-value total"><div>{{'STRINGS.TOTAL' | translate | uppercase}}</div><div>{{substance.planned_value_total}} KG</div></div>
        <div class="my-value planned"><div>{{'STRINGS.PLANNED' | translate | uppercase}}</div><div>{{substance.planned_value_current}}</div></div>
        <div class="my-value ytd"><div>{{'STRINGS.YEAR_TO_DATE_ABBR' | translate | uppercase}}</div><div>{{substance.spraying_value}}</div></div>
    </div>
    <div #chartContainer id="my-gauge-wrapper" class="prevent-select">
        <highcharts-chart
                *ngIf="gaugeOptions"
                class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isGaugeLoaded}"
                [Highcharts]="Highcharts"
                [options]="gaugeOptions"
        ></highcharts-chart>
    </div>
</div>
