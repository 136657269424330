import * as Highcharts from 'highcharts';
import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { MayaSubstanceMetrics } from '../../../../../api/maya/models/maya-substance-metrics.model';
import { TranslateService } from '@ngx-translate/core';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-maya-circular-gauge',
    templateUrl: './maya-circular-gauge.component.html',
    styleUrls: ['./maya-circular-gauge.component.less']
})
export class MayaCircularGaugeComponent {
    @HostBinding('class') class = 'toro-maya-gauge'
    @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

    private _substance: MayaSubstanceMetrics;
    @Input() set substance(value: MayaSubstanceMetrics) {
        if (value == null) { return;}

        this._substance = value;
        this.setupGauge();
    }

    get substance(): MayaSubstanceMetrics {
        return this._substance;
    }

    protected Highcharts = Highcharts;
    protected gaugeOptions: any = null;
    protected chartContainerWidth = 0;
    protected chartContainerHeight = 0;
    protected isGaugeLoaded = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private translate: TranslateService) { }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setWidthOfChartContainer() {
        if (!this.chartContainer) { return; }
        this.chartContainerWidth = this.chartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.chartContainer.nativeElement.offsetHeight - 2;
    }

    private setupGauge() {
        this.setWidthOfChartContainer();
        if (this.chartContainerWidth == 0) {
            setTimeout(() => this.setupGauge(), 100);
            return;
        }

        const self = this;

        this.gaugeOptions = {
            caption: {
                text: '<div id="my-datum-container">' +
                    '<div id="my-substance">' + self.substance.substance + '</div>' +
                    '<div class="my-value total"><div>' + self.translate.instant('STRINGS.TOTAL').toUpperCase() + '</div><div>' + self.substance.planned_value_total + ' KG</div></div>' +
                    '<div class="my-value planned"><div>' + self.translate.instant('STRINGS.PLANNED').toUpperCase() + '</div><div>' + self.substance.planned_value_current + '</div></div>' +
                    '<div class="my-value ytd"><div>' + self.translate.instant('STRINGS.YEAR_TO_DATE_ABBR').toUpperCase() + '</div><div>' + self.substance.spraying_value + '</div></div>' +
                    '</div>',
                useHTML: true,
                x: (this.chartContainerWidth / 2) - 86,
                y: -52,
                floating: true,
                margin: 0
            },
            showLegend: false,
            chart: {
                type: 'column',
                inverted: true,
                polar: true,
                width: self.chartContainerWidth,
            },
            credits: { enabled: false },
            title: { text: '' },
            pane: {
                size: '100%',
                innerSize: '20%',
                endAngle: 360,
            },
            xAxis: {
                tickInterval: 1,
                labels: { enabled: false },
                lineWidth: 0,
                gridLineWidth: 0,
            },
            yAxis: {
                reversed: true,
                labels: { enabled: false },
                lineWidth: 0,
                gridLineWidth: 0
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0,
                    pointPadding: 0,
                    groupPadding: 0.05,
                    maxPointWidth: 5,
                    pointInterval: 0.1
                }
            },
            series: [
                {
                    data: [
                        {
                            // name: 'TOTAL',
                            y: +self?.substance?.planned_value_total || 0,
                            color: '#F9B115'
                        }, {
                            // name: 'PLANNED',
                            y: +self?.substance?.planned_value_current || 0,
                            color: '#77D768'
                        }, {
                            // name: 'YTD',
                            y: +self?.substance?.spraying_value || 0,
                            color: '#36A9E1'
                        }],
                    showInLegend: false
                }
            ]
        };

        this.isGaugeLoaded = true;
    }
}
