import { MayaSite } from './maya-site.model';

export class MayaSitesAndAreas {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.data) { this.data = json.data.map(c => new MayaSite(c)); }
        }
    }

    success = false;
    message: string;
    data: MayaSite[];
}
