import { MayaSubstancesMetrics } from '../../api/maya/models/maya-substances-metrics.model';
import { MayaSitesAndAreas } from '../../api/maya/models/maya-sites-and-areas.model';

export class MayaDemoData {

    static get substancesMetrics(): MayaSubstancesMetrics {
        return new MayaSubstancesMetrics({
            "success": true,
            "message": 'Successfully retrieved data',
            "data": [
                {
                    "substance": "Nitrique",
                    "idsubstance": "e1058547-1034-44b0-945a-3351f6f80e3b",
                    "spraying_value": 8.01,
                    "planned_value_current": 0,
                    "planned_value_total": 0
                },
                {
                    "substance": "N",
                    "idsubstance": "537bd527-9326-4a7a-b59a-3ad4ef19c2bd",
                    "spraying_value": 54.54,
                    "planned_value_current": "514",
                    "planned_value_total": "514"
                },
                {
                    "substance": "Fe",
                    "idsubstance": "6f027ba9-8509-4f0c-90ce-d11c397575b5",
                    "spraying_value": 76.24,
                    "planned_value_current": 408.95,
                    "planned_value_total": 408.95
                },
                {
                    "substance": "S",
                    "idsubstance": "e20d17be-bf01-4f22-89fa-5cec13964a09",
                    "spraying_value": 71.03,
                    "planned_value_current": 0,
                    "planned_value_total": 0
                },
                {
                    "substance": "ureique",
                    "idsubstance": "e0b6f115-b09f-403a-97a2-f66c9d20dc3e",
                    "spraying_value": 29.21,
                    "planned_value_current": 0,
                    "planned_value_total": 0
                },
                {
                    "substance": "Cu",
                    "idsubstance": "d3d356b0-a042-4f74-9e89-beb25656a50a",
                    "spraying_value": 0.18,
                    "planned_value_current": 192.5,
                    "planned_value_total": 192.5
                },
                {
                    "substance": "ammoniacal",
                    "idsubstance": "3feba717-a16f-42ed-a1a5-3ae6f483c6a4",
                    "spraying_value": 8.26,
                    "planned_value_current": 385.5,
                    "planned_value_total": 385.5
                },
                {
                    "substance": "P",
                    "idsubstance": "17a67c72-9f21-4088-a3bf-551b61e3a8c8",
                    "spraying_value": 1.53,
                    "planned_value_current": 385.5,
                    "planned_value_total": 385.5
                },
                {
                    "substance": "K",
                    "idsubstance": "9dfd7093-ff4c-4cd1-ab26-8779207ad068",
                    "spraying_value": 0.04,
                    "planned_value_current": 514,
                    "planned_value_total": 514
                },
                {
                    "substance": "organique",
                    "idsubstance": "558b95f5-e5ba-41da-94db-fad7e2a4432d",
                    "spraying_value": 0.61,
                    "planned_value_current": 192.75,
                    "planned_value_total": 192.75
                },
                {
                    "substance": "MO",
                    "idsubstance": "a4e5ac30-279c-4c54-abdb-9f4b3a4554c0",
                    "spraying_value": 10.68,
                    "planned_value_current": 6425,
                    "planned_value_total": 6425
                }
            ],
            "headers": []
        })
    }

    static get sitesAndAreas(): MayaSitesAndAreas {
        return new MayaSitesAndAreas({
            "success": true,
            "message": "Successfully retrieved sites and areas",
            "data": [
                {
                    "idgroup": "7750ceae-0528-44cb-9aeb-8865602d7f6f",
                    "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43",
                    "idcompany": null,
                    "idparent_site": null,
                    "name": "Test site",
                    "latitude": 50.633531969521,
                    "longitude": 4.4766215396672,
                    "surface": 10,
                    "createdon": "2024-11-28 12:03:18",
                    "modifiedon": "2024-12-12 08:03:41",
                    "tz_correction": 0,
                    "status": 1,
                    "tags": [
                        {
                            "idtaggable": "8248635a-6f92-4027-9471-8cbd3e9e4543",
                            "idtag": "c233f33e-7c86-491c-bf0f-d427c26a30ea",
                            "tag_name": "others",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "84687225-00b0-40d8-bb75-5ef857805298",
                            "idtag": "a661dace-8bb6-4a0d-9b6f-4f80ea04740e",
                            "tag_name": "paths",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "50b3e3bb-f9c3-426c-ac78-9ff235386982",
                            "idtag": "2e49e544-473c-472e-b6e6-a9c43dcf255e",
                            "tag_name": "tee",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "691df7bb-7aa8-4303-9312-268c0f8a6e96",
                            "idtag": "84b2dac6-41a0-4e3e-b2ec-d76141224191",
                            "tag_name": "bunkers",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "a05e6ceb-0de9-4378-88b6-4dcbf530eb46",
                            "idtag": "380cfc0d-b960-4788-b2c8-677867f7d810",
                            "tag_name": "tour",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "a1f0b54e-757b-4487-9b8f-505cb83d5064",
                            "idtag": "25b869ad-d73a-4495-9884-17e39e3bb811",
                            "tag_name": "infrastructure",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-12-12T09:19:38.000000Z",
                            "surface": 1.73,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "99137f55-116e-45ed-b515-d5391fe31fc9",
                            "idtag": "2503d068-82fb-4867-a214-861e3b75f3ec",
                            "tag_name": "practice",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "aa0abaa5-717b-4621-9678-5b1f6c16c862",
                            "idtag": "ba7caa31-4d13-4610-bd7d-5f377eba5ee0",
                            "tag_name": "fairways",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "4faf2efa-829e-4a0d-8e39-99ca53878192",
                            "idtag": "d66657c9-cf15-400a-b44b-db19b2b523c5",
                            "tag_name": "Test area",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "2c811bb6-3567-4d34-b82e-4830f774f16b",
                            "idtag": "5659cd4e-1766-4c3f-b02c-2db9c5aebdba",
                            "tag_name": "driving_range",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "cf42ea55-3f1a-4f49-9c4c-e048de5f3ee8",
                            "idtag": "b9f68c65-e9d9-489a-8ed8-057a91241b5f",
                            "tag_name": "approches",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "420dd646-a84e-4f67-a504-58f16da180da",
                            "idtag": "a83f61e3-176d-4f83-9517-a6601b43f77e",
                            "tag_name": "rough",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-11-28T12:03:18.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        },
                        {
                            "idtaggable": "f52974d8-d3b9-4f8d-8048-56562a3f4a5a",
                            "idtag": "aadbd92c-8fee-4e9f-b685-e6020220651b",
                            "tag_name": "greens",
                            "createdon": "2024-11-28T12:03:18.000000Z",
                            "modifiedon": "2024-12-12T08:03:41.000000Z",
                            "surface": 1,
                            "status": 1,
                            "idsite": "f65a5f82-b7fb-47a8-ae54-1c1deb823f43"
                        }
                    ]
                }
            ]
        });
    }

}
