import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-intelli360-modal',
    templateUrl: './intelli360-modal.component.html',
    styleUrls: ['./intelli360-modal.component.less']
})
export class Intelli360ModalComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() gotoSite = new EventEmitter();

    @Input() title = '';
    @Input() isTitleCase = true;
    @Input() showLinkButton = false;
    @Input() isMiniModal = false;
    @Input() headerImgSrc: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService) { }

    ngOnInit(): void {
        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onClose());
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onGotoSite() {
        this.gotoSite.next(null);
    }

    onClose() {
        this.cancel.next(null);
    }

}
