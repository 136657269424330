import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { MayaApiService } from './maya-api.service';
import { MayaSitesAndAreas } from './models/maya-sites-and-areas.model';
import { MayaSubstancesMetrics } from './models/maya-substances-metrics.model';

@Injectable({
    providedIn: 'root'
})
export class MayaManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(private mayaApiService: MayaApiService
    ) {
        // if (environment.isDemoMode) {
        this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        // }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getMayaSitesAndAreas(): Observable<MayaSitesAndAreas> {
        return of(this.demoModeMockDataService.mayaSitesAndAreas);

        if (environment.isDemoMode) { return of(this.demoModeMockDataService.mayaSitesAndAreas); }

        return this.mayaApiService.getMayaSitesAndAreas();
    }

    getMayaSubstancesMetrics(): Observable<MayaSubstancesMetrics> {
        return of(this.demoModeMockDataService.mayaSubstancesMetrics);

        if (environment.isDemoMode) { return of(this.demoModeMockDataService.mayaSubstancesMetrics); }

        return this.mayaApiService.getMayaSubstancesMetrics();
    }

}
