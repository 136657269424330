import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import * as Highcharts from 'highcharts';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { forkJoin } from 'rxjs';
import { MayaArea } from '../../../../../api/maya/models/maya-area.model';
import { MayaManagerService } from '../../../../../api/maya/maya-manager.service';
import { MayaSite } from '../../../../../api/maya/models/maya-site.model';
import { MayaSitesAndAreas } from '../../../../../api/maya/models/maya-sites-and-areas.model';
import { MayaSubstanceMetrics } from '../../../../../api/maya/models/maya-substance-metrics.model';
import { MayaSubstancesMetrics } from '../../../../../api/maya/models/maya-substances-metrics.model';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-widget-maya',
    templateUrl: './widget-maya.component.html',
    styleUrls: ['./widget-maya.component.less']
})
export class WidgetMayaComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

    iconColor = 'black';
    title = 'WIDGET.MAYA';

    Highcharts = Highcharts;
    gaugeOptions: any = null;

    gaugeContainerWidth = 233;
    isGaugeLoaded = true;

    protected chartContainerWidth = 0;
    protected chartContainerHeight = 0;
    protected substanceN: MayaSubstanceMetrics;
    protected substanceP: MayaSubstanceMetrics;
    protected substanceK: MayaSubstanceMetrics;
    protected coursesSelectList: SelectItem[] = [];
    protected areasSelectList: SelectItem[] = [];
    protected selectedAreaId: number = 1;

    // TODO: TEMPORARY?
    protected selectedCourse = 'Riverside Golf Club';
    protected selectedArea = 'Greens';

    private mayaSites: MayaSitesAndAreas;
    private mayaMetrics: MayaSubstancesMetrics;

    private _selectedSite: MayaSite;
    protected set selectedSite(value: MayaSite) {
        this._selectedSite = value;
        this.setAreasSelectList();
    }

    protected get selectedSite(): MayaSite {
        return this._selectedSite;
    }

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private mayaManager: MayaManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.MayaWidgetName;
    }

    protected getWidgetData(isManualRefresh) {

        const sources = {
            sites: this.mayaManager.getMayaSitesAndAreas().pipe(take(1)),
            metrics: this.mayaManager.getMayaSubstancesMetrics().pipe(take(1))
        };

        forkJoin(sources)
            .pipe(finalize(() => { this.isBusy = false }))
            .subscribe({
                next: ({sites, metrics}) => {
                    this.mayaSites = sites;
                    this.mayaMetrics = metrics;

                    this.setSitesSelectList();

                    this.substanceN = this.mayaMetrics.data.find(s => s.substance == 'N');
                    this.substanceP = this.mayaMetrics.data.find(s => s.substance == 'P');
                    this.substanceK = this.mayaMetrics.data.find(s => s.substance == 'K');
                },
                error: (err) => {
                    console.log(err);
                }
            })
    }

    private setSitesSelectList() {
        this.coursesSelectList = [];
        this.areasSelectList = [];

        if (this.mayaSites?.data?.length > 0) {
            this.mayaSites.data.forEach((item) => {
                this.coursesSelectList.push({ label: item.name, value: item})
            })

            this.selectedSite = this.mayaSites.data[0];
        }
    }

    private setAreasSelectList() {
        this.areasSelectList = [];

        if (this.selectedSite?.tags != null) {
            const sortedAreas = this.selectedSite.tags.sort((a, b) => a.tag_name.localeCompare(b.tag_name));

            sortedAreas.forEach((area: MayaArea) => {
                this.areasSelectList.push({ label: area.tag_name, value: area})
            })
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onNavigateToSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_MAYA' });
        setTimeout(() => window.open(environment.strikeGuardSiteUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }
}
