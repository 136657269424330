import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-widget-intelli360-asset-down',
    templateUrl: './widget-intelli360-asset-down.component.html',
    styleUrls: ['./widget-intelli360-asset-down.component.less']
})
export class WidgetIntelli360AssetDownComponent extends ToroDashboardWidget implements OnInit {

    iconColor = 'black';
    title = ''; //'WIDGET.INTELLI360_ASSET_DOWN';

    protected showDesktopModal = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.Intelli360AssetDownWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        setTimeout(() => this.isBusy = false, 1000);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_INTELLI360' });
        setTimeout(() => window.open(environment.intelli360SiteUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }
}
