import * as moment from 'moment';

export class MayaArea {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.createdon) this.createdon = moment.utc(json.createdon).toDate();
            if (json.modifiedon) this.modifiedon = moment.utc(json.modifiedon).toDate();
        }
    }

    idtaggable: string;
    idtag: string;
    tag_name: string;
    createdon: Date;
    modifiedon: Date;
    surface: number;
    status: number;
    idsite: string;

}
