import { MayaSubstanceMetrics } from './maya-substance-metrics.model';

export class MayaSubstancesMetrics {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.data) { this.data = json.data.map(c => new MayaSubstanceMetrics(c)); }
        }
    }

    success = false;
    message: string;
    data: MayaSubstanceMetrics[];
}
