export class MayaSubstanceMetrics {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    substance: string;
    idsubstance: string;
    spraying_value: number;
    planned_value_current: number;
    planned_value_total: number;
}
