import { ConfirmationService, MessageService } from 'primeng/api';
import {
    faArrowAltCircleLeft,
    faArrowCircleDown,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowDown, faArrowLeft,
    faArrowRight,
    faArrowsAltH,
    faArrowsAltV,
    faArrowUp,
    faAsterisk,
    faBars, faBolt,
    faBullhorn, faBullseye, faCaretLeft, faCaretRight, faChartLine,
    faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faEllipsisV, faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle, faExpand, faExpandAlt, faExpandArrowsAlt, faExternalLinkAlt,
    faFileInvoiceDollar,
    faInfoCircle,
    faLock,
    faLockOpen,
    faLongArrowAltDown,
    faLongArrowAltUp, faMagic, faMapMarker,
    faMapMarkerAlt,
    faMinusCircle, faPauseCircle,
    faPlusCircle,
    faPrint,
    faRedo, faRuler,
    faShippingFast,
    faSignOutAlt,
    faSquare, faStar,
    faSync,
    faTasks,
    faTimes, faTimesCircle,
    faTint,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import {
    faBell, faCalendarAlt, faCalendarTimes, faClock, faComments, faListAlt, faQuestionCircle, faCircle as farCircle, faUserCircle, faWindowClose,
} from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faInstagram, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Injector, NgModule } from '@angular/core';
import { AccountIssueComponent } from './components/account-issue/account-issue.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ColorRangeComponent } from './components/color-range/color-range.component';
import { CommonModule } from '@angular/common';
import { ComponentShieldComponent } from './components/component-shield/component-shield.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DialogModule } from 'primeng/dialog';
import { DragOverlayComponent } from './components/drag-overlay/drag-overlay.component';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { FillPipe } from './pipes/fill.pipe';
import { FormsModule } from '@angular/forms';
import { GMapModule } from 'primeng/gmap';
import { HighchartsChartModule } from 'highcharts-angular';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxLeafletFullscreenModule } from '@runette/ngx-leaflet-fullscreen';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { WaitingIndicatorComponent } from './components/waiting-indicator/waiting-indicator.component';
import { WidgetLinkButtonComponent } from './components/widget-link-button/widget-link-button.component';

export let AppInjector: Injector;

@NgModule({
    declarations: [
        WaitingIndicatorComponent,
        WidgetLinkButtonComponent,
        DatePickerComponent,
        ComponentShieldComponent,
        FillPipe,
        AccountIssueComponent,
        DragOverlayComponent,
        ColorRangeComponent,
    ],
    imports: [
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        ColorPickerModule,      // For Test
        CommonModule,
        ConfirmDialogModule,
        DialogModule,
        DropdownModule,
        FontAwesomeModule,
        FormsModule,
        GMapModule,
        HighchartsChartModule,
        InputMaskModule,
        InputNumberModule,
        InputTextModule,        // For Test
        ListboxModule,
        MenuModule,
        MultiSelectModule,
        NgxLeafletFullscreenModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        SidebarModule,
        TableModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        TranslateModule,
        EditorModule,
        FileUploadModule,
        CarouselModule,
        PasswordModule,
        InputSwitchModule,
        BreadcrumbModule
    ],
    exports: [
        AccountIssueComponent,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        ColorPickerModule,      // For Test
        ComponentShieldComponent,
        ConfirmDialogModule,
        DatePickerComponent,
        DialogModule,
        DragOverlayComponent,
        DropdownModule,
        FillPipe,
        FontAwesomeModule,
        FormsModule,
        GMapModule,
        HighchartsChartModule,
        InputMaskModule,
        InputNumberModule,
        InputTextModule,        // For Test
        ListboxModule,
        MenuModule,
        MultiSelectModule,
        NgxLeafletFullscreenModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        SidebarModule,
        TableModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        WaitingIndicatorComponent,
        WidgetLinkButtonComponent,
        EditorModule,
        FileUploadModule,
        SliderModule,
        CarouselModule,
        PasswordModule,
        InputSwitchModule,
        BreadcrumbModule,
        ColorRangeComponent
    ],
    providers: [
        MessageService,
        ConfirmationService,
    ]
})
export class SharedModule {
    constructor(faIconLibrary: FaIconLibrary,
                injector: Injector
    ) {
        AppInjector = injector;
        faIconLibrary.addIcons(
            faMapMarkerAlt, faFacebookF, faTwitter, faYoutube, faPinterest, faInstagram, faSquare, faWindowClose, faAsterisk, faEllipsisV, faSignOutAlt,
            faRedo, faBars, faArrowsAltV, faArrowsAltH, faLongArrowAltUp, faLongArrowAltDown, faExclamationTriangle, faTimes, faTint, faPlusCircle,
            faMinusCircle, faUserCircle, faInfoCircle, faArrowCircleLeft, faArrowCircleRight, faCalendarAlt, faCalendarTimes, faBell, faSync, faCircle,
            faComments, faBullhorn, faShippingFast, faArrowCircleUp, faArrowCircleDown, faExclamation, faPrint, faFileInvoiceDollar, faClock, faUser,
            faTasks, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faCheckCircle, faLock, faLockOpen, faArrowAltCircleLeft, faExclamationCircle, faPauseCircle,
            faRuler, faExternalLinkAlt, faCaretLeft, faCaretRight, faCircle, faMagic, faStar, faChartLine, faExchangeAlt, faChevronRight, faBullseye, farCircle,
            faMapMarker, faQuestionCircle, faListAlt, faExpand, faExpandAlt, faExpandArrowsAlt, faChevronDown, faTimesCircle, faBolt, faChevronUp, faChevronLeft
        );
    }
}
