<div id="toro-dashboard-container" [ngClass]="{'isMobile': widgetManager.isMobileGridDisplay}" style="position: relative;">

    <div *ngIf="isDashboardInitialized && (!items || items?.length < 1) && !isGridResizing" class="toro-dashboard-no-widgets">
        <span class="tdnw-main">{{'STRINGS.PLEASE_SELECT_WIDGETS' | translate}}</span>
        <span class="tdnw-secondary">({{(!widgetManager.isMobileGridDisplay ? 'STRINGS.USE_SELECTOR_IN_HEADER' : 'STRINGS.USE_SELECTOR_IN_MOBILE_MENU') | translate}})</span>
    </div>

    <gridster #theGrid *ngIf="!hideGrid && items && items.length > 0" class="toro-gridster" [options]="gridsterOptions" (scroll)="onGridScroll($event)" id="the-grid">

        <gridster-item class="toro-gridster-item" [item]="item" *ngFor="let item of items">

            <ng-container [ngSwitch]="item.type">
                <toro-widget-test *ngSwitchCase="ToroWidgetType.Test" [associatedWidget]="item"></toro-widget-test>
                <toro-widget-my-turf-maintenance *ngSwitchCase="ToroWidgetType.MyTurfMaintenance" [associatedWidget]="item"></toro-widget-my-turf-maintenance>
                <toro-widget-my-turf-orders *ngSwitchCase="ToroWidgetType.MyTurfOrders" [associatedWidget]="item"></toro-widget-my-turf-orders>
                <toro-widget-weather-radar *ngSwitchCase="ToroWidgetType.WeatherRadar" [associatedWidget]="item"></toro-widget-weather-radar>
                <toro-widget-turf-guard-moisture *ngSwitchCase="ToroWidgetType.TurfGuardMoisture" [associatedWidget]="item"></toro-widget-turf-guard-moisture>
                <toro-widget-turf-guard-salinity *ngSwitchCase="ToroWidgetType.TurfGuardSalinity" [associatedWidget]="item"></toro-widget-turf-guard-salinity>
                <toro-widget-turf-guard-temperature *ngSwitchCase="ToroWidgetType.TurfGuardTemperature" [associatedWidget]="item"></toro-widget-turf-guard-temperature>
                <toro-widget-frost-warning *ngSwitchCase="ToroWidgetType.FrostWarning" [associatedWidget]="item"></toro-widget-frost-warning>
                <toro-widget-evapotranspiration *ngSwitchCase="ToroWidgetType.Evapotranspiration" [associatedWidget]="item"></toro-widget-evapotranspiration>
                <toro-widget-weather-graphs *ngSwitchCase="ToroWidgetType.WeatherGraphs" [associatedWidget]="item"></toro-widget-weather-graphs>
                <toro-widget-lynx-running-stations *ngSwitchCase="ToroWidgetType.LynxRunningStations" [associatedWidget]="item"></toro-widget-lynx-running-stations>
                <toro-widget-lynx-cloud *ngSwitchCase="ToroWidgetType.LynxStationStatuses" [associatedWidget]="item"></toro-widget-lynx-cloud>
                <toro-widget-lynx-pump-stations *ngSwitchCase="ToroWidgetType.LynxPumpStations" [associatedWidget]="item"></toro-widget-lynx-pump-stations>
                <toro-widget-lynx-local-current-weather *ngSwitchCase="ToroWidgetType.LynxLocalCurrentWeather" [associatedWidget]="item"></toro-widget-lynx-local-current-weather>
                <toro-widget-lynx-local-weather-graphs *ngSwitchCase="ToroWidgetType.LynxLocalWeatherGraphs" [associatedWidget]="item"></toro-widget-lynx-local-weather-graphs>
                <toro-widget-local-weather-forecast *ngSwitchCase="ToroWidgetType.LocalWeatherForecast" [associatedWidget]="item"></toro-widget-local-weather-forecast>
                <toro-widget-nsn *ngSwitchCase="ToroWidgetType.Nsn" [associatedWidget]="item"></toro-widget-nsn>
                <toro-widget-perry-weather *ngSwitchCase="ToroWidgetType.PerryWeather" [associatedWidget]="item"></toro-widget-perry-weather>
                <toro-widget-playbooks *ngSwitchCase="ToroWidgetType.Playbooks" [associatedWidget]="item"></toro-widget-playbooks>
                <toro-widget-equipment-tracking *ngSwitchCase="ToroWidgetType.EquipmentTracking" [associatedWidget]="item"></toro-widget-equipment-tracking>
                <toro-widget-geo-fencing-tracking *ngSwitchCase="ToroWidgetType.GeoFencing" [associatedWidget]="item"></toro-widget-geo-fencing-tracking>
                <toro-widget-green-sight *ngSwitchCase="ToroWidgetType.GreenSight" [associatedWidget]="item"></toro-widget-green-sight>
                <toro-widget-turf-cloud-course-trakk *ngSwitchCase="ToroWidgetType.TurfCloudCourseTrakk" [associatedWidget]="item"></toro-widget-turf-cloud-course-trakk>
                <toro-widget-turf-cloud-labor-stats *ngSwitchCase="ToroWidgetType.TurfCloudLaborStats" [associatedWidget]="item"></toro-widget-turf-cloud-labor-stats>
                <toro-widget-task-tracker-labor *ngSwitchCase="ToroWidgetType.TaskTrackerLaborSummary" [associatedWidget]="item"></toro-widget-task-tracker-labor>
                <toro-widget-task-tracker-measurements *ngSwitchCase="ToroWidgetType.TaskTrackerDailyMeasurements" [associatedWidget]="item"></toro-widget-task-tracker-measurements>
                <toro-widget-task-tracker-safety *ngSwitchCase="ToroWidgetType.TaskTrackerSafety" [associatedWidget]="item"></toro-widget-task-tracker-safety>
                <toro-widget-decision-tree *ngSwitchCase="ToroWidgetType.DecisionTree" [associatedWidget]="item"></toro-widget-decision-tree>
                <toro-widget-ez-locator *ngSwitchCase="ToroWidgetType.EzLocator" [associatedWidget]="item"></toro-widget-ez-locator>
                <toro-widget-gcsaa *ngSwitchCase="ToroWidgetType.Gcsaa" [associatedWidget]="item"></toro-widget-gcsaa>
                <toro-widget-gcsaa-alt *ngSwitchCase="ToroWidgetType.GcsaaAlt" [associatedWidget]="item"></toro-widget-gcsaa-alt>
                <toro-widget-weather-averages *ngSwitchCase="ToroWidgetType.WeatherAverages" [associatedWidget]="item"></toro-widget-weather-averages>
                <toro-widget-pogo  *ngSwitchCase="ToroWidgetType.Pogo" [associatedWidget]="item"></toro-widget-pogo>
                <toro-widget-soil-scout-moisture *ngSwitchCase="ToroWidgetType.SoilScoutMoisture" [associatedWidget]="item"></toro-widget-soil-scout-moisture>
                <toro-widget-soil-scout-salinity *ngSwitchCase="ToroWidgetType.SoilScoutSalinity" [associatedWidget]="item"></toro-widget-soil-scout-salinity>
                <toro-widget-soil-scout-temperature *ngSwitchCase="ToroWidgetType.SoilScoutTemperature" [associatedWidget]="item"></toro-widget-soil-scout-temperature>
                <toro-widget-soil-scout-mobile *ngSwitchCase="ToroWidgetType.SoilScoutMobile" [associatedWidget]="item"></toro-widget-soil-scout-mobile>
                <toro-widget-task-tracker-turf-rad *ngSwitchCase="ToroWidgetType.TaskTrackerTurfRad" [associatedWidget]="item"></toro-widget-task-tracker-turf-rad>
                <toro-widget-soil-scout-map *ngSwitchCase="ToroWidgetType.SoilScoutMap" [associatedWidget]="item"></toro-widget-soil-scout-map>
                <toro-widget-notes *ngSwitchCase="ToroWidgetType.Notes" [associatedWidget]="item"></toro-widget-notes>
                <toro-widget-dtn-weather-forecast *ngSwitchCase="ToroWidgetType.DtnWeather" [associatedWidget]="item"></toro-widget-dtn-weather-forecast>
                <toro-widget-spectrum-moisture *ngSwitchCase="ToroWidgetType.SpectrumMoisture" [associatedWidget]="item"></toro-widget-spectrum-moisture>
                <toro-widget-strike-guard *ngSwitchCase="ToroWidgetType.StrikeGuard" [associatedWidget]="item"></toro-widget-strike-guard>
                <toro-widget-maya *ngSwitchCase="ToroWidgetType.Maya" [associatedWidget]="item"></toro-widget-maya>
                <toro-widget-intelli360-maintenance *ngSwitchCase="ToroWidgetType.Intelli360Maintenance" [associatedWidget]="item"></toro-widget-intelli360-maintenance>
                <toro-widget-intelli360-asset-down *ngSwitchCase="ToroWidgetType.Intelli360AssetDown" [associatedWidget]="item"></toro-widget-intelli360-asset-down>
                <toro-widget-intelli360-work-orders *ngSwitchCase="ToroWidgetType.Intelli360WorkOrders" [associatedWidget]="item"></toro-widget-intelli360-work-orders>
                <toro-widget-intelli360-operating-status *ngSwitchCase="ToroWidgetType.Intelli360OperatingStatus" [associatedWidget]="item"></toro-widget-intelli360-operating-status>

                <!--                <toro-widget-lynx-pump-station *ngSwitchCase="ToroWidgetType.LynxPumpStation" [associatedWidget]="item"></toro-widget-lynx-pump-station>-->
                <!--                <toro-widget-lynx-pump-stations-with-gauges *ngSwitchCase="ToroWidgetType.LynxPumpStationsWithGauges" [associatedWidget]="item"></toro-widget-lynx-pump-stations-with-gauges>-->
            </ng-container>

        </gridster-item>

    </gridster>

    <toro-waiting-indicator
            class="widget-non-draggable"
            *ngIf="showSystemWaitingIndicator"
            [backgroundColor]="'rgba(0,0,0,0.6)'"
            [color]="'white'"
            [text]="systemWaitingIndicatorText"
            [isVisible]="showSystemWaitingIndicator">
    </toro-waiting-indicator>

    <div id="debug-panel" *ngIf="showDebugPanel">
       <div id="debug-panel-content">
           <div *ngFor="let entry of debugMessages">
               {{entry}}
           </div>
        </div>
    </div>

</div>
