import { Component, Input } from '@angular/core';
import { MayaSubstanceMetrics } from '../../../../../api/maya/models/maya-substance-metrics.model';

@Component({
    selector: 'toro-maya-mini-panel',
    templateUrl: './maya-mini-panel.component.html',
    styleUrls: ['./maya-mini-panel.component.less']
})
export class MayaMiniPanelComponent {

    @Input() substance: MayaSubstanceMetrics;

    constructor() { }
}
