import { ApiService, HttpMethod } from '../_common/api.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PogoProperty } from './models/pogo-property.model';

@Injectable({
    providedIn: 'root'
})
export class PogoApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getProperty(apiKey: string): Observable<any> {
        return this.apiService.apiRequest<any>(this.getPropertyUrl(apiKey), HttpMethod.Get)
            .pipe(map((data: PogoProperty) => new PogoProperty(data)))
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${environment.pogoApiUrl}`; }

    private getPropertyUrl(apiKey: string) { return `${PogoApiService.baseUrl}/widget-1?key=${apiKey}`; }
}
