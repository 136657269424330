export namespace ToroAnalyticsEnums {

    export enum AnalyticsCategory {
        Application = 'Application',
        Feedback = 'Feedback',
        Interaction = 'Interaction',
        Notifications = 'Notifications',
        Preferences = 'Preferences',
        Site = 'Site',
        User = 'User',
        Widgets = 'Widgets',
        Error = 'Error'
    }

    export enum AnalyticsEvent {
        AppStart = 'Application: Start',
        AppActive = 'Application: Active',
        AppStop = 'Application: Stop',
        DashboardLocked = 'Dashboard arrangement: Locked',
        DashboardUnlocked = 'Dashboard arrangement: Unlocked',
        DataBadDataReceived = 'Data: Bad Data Received',
        DataFetchError = 'Data: Unable to Fetch Data',
        FeedbackButtonClicked = 'Feedback Dialog: Opened',
        FeedbackDialogCancelled = 'Feedback Dialog: Cancelled',
        FeedbackSubmitted = 'Feedback Dialog: Submitted',
        NotificationPanelOpened = 'Notification Panel: Opened',
        NotificationPanelClosed = 'Notification Panel: Closed',
        NotificationReceived = 'Notification Received',
        PreferencesDialogOpened = 'Preferences Dialog: Opened',
        PreferencesDialogCancelled = 'Preferences Dialog: Cancelled',
        PreferencesDialogSaved = 'Preferences Dialog: Saved',
        UserLogin = 'User: Login',
        UserLogout = 'User: Logout',
        SiteLoaded = 'Site: Loaded',

        // Analytics Widget Names
        DecisionTreeWidgetName = 'Decision Tree Widget',
        GeoFencingWidgetName = 'Geofencing Widget',
        DtnWeatherForecastWidgetName = 'Local Weather Forecast (DTN) Widget',
        EvapotranspirationWidgetName = 'Evapotranspiration Widget',
        EzLocatorWidgetName = "ezLocator Widget",
        FrostWarningWidgetName = 'Frost Warning Widget',
        GcsaaWidgetName = "GCSAA Newsfeed Widget",
        GcsaaAltWidgetName = "GCSAA Newsfeed (ALT) Widget",
        GreenSightWidgetName = 'GreenSight Widget',
        Intelli360AssetDownWidgetName = 'Intelli360 - AssetDown',
        Intelli360MaintenanceWidgetName = 'Intelli360 - Maintenance Due',
        Intelli360OperatingStatusWidgetName = 'Intelli360 - Operating Status',
        Intelli360WorkOrdersWidgetName = 'Intelli360 - Work Orders',
        LocalWeatherForecastWidgetName = 'Local Weather Forecast Widget',
        LynxLocalCurrentWeatherWidgetName = 'Lynx Local Current Weather Widget',
        LynxLocalWeatherGraphsWidgetName = 'Lynx Local Weather Graphs Widget',
        LynxPumpStationsWidgetName = 'Lynx Pump Stations Widget',
        LynxRunningStationsWidgetName = 'Lynx Running Stations Widget',
        LynxStationStatusesWidgetName = 'Lynx Station Statuses Widget',
        MayaWidgetName = "Maya Widget",
        MyTurfMaintenanceWidgetName = 'myTurf Maintenance Widget',
        MyTurfOrdersWidgetName = 'myTurf Orders Widget',
        NsnChat = 'NSN Chat',
        NsnWidgetName = 'NSN Widget',
        PerryWeatherWidgetName = 'Perry Weather Widget',
        PlaybooksWidgetName = 'Playbooks Widget',
        PogoWidgetName = "Pogo Widget",
        SoilScoutMobileWidgetName = "Soil Scout Mobile Widget",
        SoilScoutMoistureWidgetName = "Soil Scout Moisture Widget",
        SoilScoutSalinityWidgetName = "Soil Scout Salinity Widget",
        SoilScoutTemperatureWidgetName = "Soil Scout Temperature Widget",
        SoilScoutMapWidgetName = "Soil Scout Map Widget",
        SpectrumMoistureName = "Spectrum Moisture",
        StrikeGuardWidgetName = "Strike Guard Widget",
        TaskTrackerDailyMeasurements = 'taskTracker Daily Measurements Widget',
        TaskTrackerLaborSummary = 'taskTracker Labor Summary Widget',
        TaskTrackerSafetyAndTraining = 'taskTracker Safety and Training',
        TaskTrackerTurfRad = 'taskTracker turfRad',
        TrackingWidgetName = 'Tracking Widget',
        TurfCloudCourseTrakkWidgetName = 'TurfCloud CourseTrakk Widget',
        TurfCloudLaborStatsWidgetName = 'TurfCloud Labor Stats Widget',
        TurfGuardMoistureWidgetName = 'TurfGuard Moisture Widget',
        TurfGuardSalinityWidgetName = 'TurfGuard Salinity Widget',
        TurfGuardTemperatureWidgetName = 'TurfGuard Temperature Widget',
        WeatherAveragesWidgetName = "Weather Averages Widget",
        WeatherGraphsWidgetName = 'Weather Graphs Widget',
        WeatherRadarWidgetName = 'Weather Radar Widget',
        NotesWidgetName = 'Notes Widget',

        // Common Widget Events
        DataSettingsShowDialog = 'Data Settings Dialog: Opened',
        DataSettingsCancelDialog = 'Data Settings Dialog: Cancelled',
        DataSettingsUpdate = 'Data Settings Dialog: Saved',
        ManualDataRefresh = 'Data Refresh Requested',
        WidgetAdded = 'Widget: Added',
        WidgetLoaded = 'Widget: Loaded',
        WidgetLocationChanged = 'Widget: Location Changed',
        WidgetRemoved = 'Widget: Removed',
        WidgetResized = 'Widget: Resized',

        // Widget custom events
        GreenSightWidgetAddFavoriteClicked = 'GreenSight Widget - Add Favorite Clicked',
        GreenSightWidgetAddFavoriteCancelled = 'GreenSight Widget - Add Favorite Cancelled',
        GreenSightWidgetDateChanged = 'GreenSight Widget - Date Changed',
        GreenSightWidgetFavoriteDeleted = 'GreenSight Widget - Favorite Deleted',
        GreenSightWidgetFavoriteSaved = 'GreenSight Widget - Favorite Saved',
        GreenSightWidgetOverlayChanged = 'GreenSight Widget - Overlay Changed',
        GreenSightWidgetMiniMapClicked = 'GreenSight Widget - Mini Map Clicked',
        GreenSightWidgetRecenterMap = 'GreenSight Widget - Recenter Map',
        GreenSightWidgetResetMap = 'GreenSight Widget - Reset Map',
        GreenSightWidgetViewerChanged = 'GreenSight Widget - Viewer Changed',

        LynxUpdateTopologyRequest = 'Lynx: Update Topology Request',
        LynxLocalWeatherGraphsWidgetHumidityGraphDisplayed = 'Lynx Local Weather Graphs Widget: Humidity Graph Displayed',
        LynxLocalWeatherGraphsWidgetHumidityGraphHidden = 'Lynx Local Weather Graphs Widget: Humidity Graph Hidden',
        LynxLocalWeatherGraphsWidgetPrecipitationGraphDisplayed = 'Lynx Local Weather Graphs Widget: Precipitation Graph Displayed',
        LynxLocalWeatherGraphsWidgetPrecipitationGraphHidden = 'Lynx Local Weather Graphs Widget: Precipitation Graph Hidden',
        LynxLocalWeatherGraphsWidgetTemperatureGraphDisplayed = 'Lynx Local Weather Graphs Widget: Temperature Graph Displayed',
        LynxLocalWeatherGraphsWidgetTemperatureGraphHidden = 'Lynx Local Weather Graphs Widget: Temperature Graph Hidden',
        LynxLocalWeatherGraphsWidgetWindGraphDisplayed = 'Lynx Local Weather Graphs Widget: Wind Graph Displayed',
        LynxLocalWeatherGraphsWidgetWindGraphHidden = 'Lynx Local Weather Graphs Widget: Wind Graph Hidden',
        LynxPumpStationsWidgetSettingsDisplayed = 'Lynx Pump Stations Widget: Settings Displayed',
        LynxRunningStationsWidgetCourseChanged = 'Lynx Running Stations Widget: Course Changed',
        LynxStationStatusesWidgetCourseChanged = 'Lynx Station Statuses Widget: Course Changed',
        LynxRunningStationsWidgetViewStationDetails = 'Lynx Running Stations Widget: View Station Details',

        MyTurfMaintenanceWidgetViewAssets = 'myTurf Maintenance Widget: View Assets',
        MyTurfOrdersWidgetViewCarts = 'myTurf Orders Widget: View Carts',
        MyTurfWidgetSignIn = 'myTurf Widget: Sign in ',
        MyTurfWidgetGoToMyTurf = 'myTurf Widget: Go to myTurf',

        NsnChatClosed = 'NSN Chat: Closed',
        NsnChatOpened = 'NSN Chat: Opened',

        NsnWidgetNsnButtonClicked = 'NSN Widget: NSN Button Clicked',
        NsnWidgetAnnouncementsButtonClicked = 'NSN Widget: Announcements Button Clicked',
        NsnWidgetShipmentsButtonClicked = 'NSN Widget: Shipments Button Clicked',
        NsnWidgetConnectDeviceClicked = 'NSN Widget: Connect Device Clicked',

        PlaybooksWidgetCancelCourseIdDialog = 'Playbooks Widget Course Id Dialog: Cancelled',
        PlaybooksWidgetClickPrintLink = 'Playbooks Widget: Click Print Link',
        PlaybooksWidgetFilterGdd = 'Playbooks Widget: Change GDD Area',
        PlaybooksWidgetFilterNutrients = 'Playbooks Widget: Change Nutrients Area',
        PlaybooksWidgetGoToDashboard = 'Playbooks Widget: Go to Dashboard',
        PlaybooksWidgetGoToInventory = 'Playbooks Widget: Go to Inventory',
        PlaybooksWidgetGoToLabels = 'Playbooks Widget: Go to Labels',
        PlaybooksWidgetGoToNewLog = 'Playbooks Widget: Go to New Log',
        PlaybooksWidgetGoToReports = 'Playbooks Widget: Go to Reports',
        PlaybooksWidgetSetCourseId = 'Playbooks Widget: Course Id Set/Changed',
        PlaybooksWidgetShowCourseIdDialog = 'Playbooks Widget Course Id Dialog: Opened',
        PlaybooksWidgetShowPrintDialog = 'Playbooks Widget: Show Print Dialog',

        SoilScoutWidgetShowALoginDialog = 'Soil Scout Login Dialog: Opened',
        SoilScoutWidgetShowAThresholdsDialog = 'Soil Scout Thresholds Dialog: Opened',
        SoilScoutWidgetViewMapAssetDetails = 'Soil Scout: View Map Scout Details',
        SoilScoutRecenterMap = 'Soil Scout Map: Recenter Map',

        StrikeGuardWidgetShowApiKeyDialog = 'Strike Guard ID Dialog: Opened',
        StrikeGuardWidgetSaveApiKeyDialog = 'Strike Guard ID Dialog: Saved',

        TaskTrackerWidgetShowApiKeyDialog = 'taskTracker API Key Dialog: Opened',
        TaskTrackerWidgetSaveApiKeyDialog = 'taskTracker API Key Dialog: Saved',
        TaskTrackerWidgetCancelApiKeyDialog = 'taskTracker API Key Dialog: Cancelled',
        TaskTrackerGoToTrackingSite = 'taskTracker: Go to taskTracker Site',

        TrackingWidgetCancelAccountIdDialog = 'Tracking Widget Account Id Dialog: Cancelled',
        TrackingWidgetGoToTrackingSite = 'Tracking Widget: Go to Tracking Site',
        TrackingWidgetSavedAccountIdDialog = 'Tracking Widget Account Id Dialog: Saved',
        TrackingWidgetShowAccountIdDialog = 'Tracking Widget Account Id Dialog: Opened',
        TrackingWidgetViewAssets = 'Tracking Widget: View Assets',
        TrackingWidgetViewAssetsMap = 'Tracking Widget: View Assets Map',
        TrackingWidgetViewMapAssetDetails = 'Tracking Widget: View Map Asset Details',

        PwWidgetCancelApiKeyDialog = 'Perry Weather Widget Integration Key Dialog: Cancelled',
        PwWidgetSavedApiKeyDialog = 'Perry Weather Widget Integration Key Dialog: Saved',
        PwWidgetShowApkKeyDialog = 'Perry Weather Widget Integration Key Dialog: Opened',

        PogoWidgetCancelApiKeyDialog = 'Pogo Widget API Key Dialog: Cancelled',
        PogoWidgetSavedApiKeyDialog = 'Pogo Widget API Key Dialog: Saved',
        PogoWidgetShowApkKeyDialog = 'Pogo Widget API Key Dialog: Opened',

        SpectrumWidgetCancelApiKeyDialog = 'Spectrum Widget API Key Dialog: Cancelled',
        SpectrumWidgetSavedApiKeyDialog = 'Spectrum Widget API Key Dialog: Saved',
        SpectrumWidgetShowApkKeyDialog = 'Spectrum Widget API Key Dialog: Opened',

        TurfCloudLaborStatsWidgetDateChanged = 'TurfCloud Labor Stats Widget - Date Changed',
        TurfCloudLaborStatsWidgetGoToTurfCloud = 'TurfCloud Labor Stats Widget - Go to TurfCloud',
        TurfCloudCourseTrakkWidgetGoToTurfCloud = 'TurfCloud CourseTrakk Widget - Go to TurfCloud',

        TurfGuardWidgetUpdateTopology = 'TurfGuard Widget: Update Topology',
        TurfGuardWidgetViewAlerts = 'TurfGuard Widget: View Alerts',
        TurfGuardWidgetViewHoles = 'TurfGuard Widget: View Holes',
        TurfGuardWidgetViewSensors = 'TurfGuard Widget: View Sensors',

        WeatherGraphsWidgetPrecipitationGraphDisplayed = 'Weather Graphs Widget: Precipitation Graph Displayed',
        WeatherGraphsWidgetPrecipitationGraphHidden = 'Weather Graphs Widget: Precipitation Graph Hidden',
        WeatherGraphsWidgetRelativeHumidityGraphDisplayed = 'Weather Graphs Widget: Relative Humidity Graph Displayed',
        WeatherGraphsWidgetRelativeHumidityGraphHidden = 'Weather Graphs Widget: Relative Humidity Graph Hidden',
        WeatherGraphsWidgetSolarRadiationGraphDisplayed = 'Weather Graphs Widget: Solar Radiation Graph Displayed',
        WeatherGraphsWidgetSolarRadiationGraphHidden = 'Weather Graphs Widget: Solar Radiation Graph Hidden',
        WeatherGraphsWidgetTemperatureGraphDisplayed = 'Weather Graphs Widget: Temperature Graph Displayed',
        WeatherGraphsWidgetTemperatureGraphHidden = 'Weather Graphs Widget: Temperature Graph Hidden',
        WeatherGraphsWidgetWindGraphDisplayed = 'Weather Graphs Widget: Wind Graph Displayed',
        WeatherGraphsWidgetWindGraphHidden = 'Weather Graphs Widget: Wind Graph Hidden',

        WeatherRadarShowLegend = 'Weather Radar Widget: Show Legend',
        WeatherRadarHideLegend = 'Weather Radar Widget: Hide Legend',
        WeatherRadarRecenterMap = 'Weather Radar Widget: Recenter Map',
    }

}
