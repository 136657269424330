import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MayaSitesAndAreas } from './models/maya-sites-and-areas.model';
import { MayaSubstancesMetrics } from './models/maya-substances-metrics.model';

@Injectable({
    providedIn: 'root'
})
export class MayaApiService {

    // private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    // constructor(http: HttpClient
    // ) {
    //     this.apiService = new ApiService(http);
    // }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getMayaSitesAndAreas(): Observable<MayaSitesAndAreas> {

        return of(new MayaSitesAndAreas({}));
    }

    getMayaSubstancesMetrics(): Observable<MayaSubstancesMetrics> {
        // return this.apiService.apiRequest<any>(this.getPropertyUrl(apiKey), HttpMethod.Get)
        //     .pipe(map((data: PogoProperty) => new PogoProperty(data)))

        return of(new MayaSubstancesMetrics({}))
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    // private static get baseUrl(): string { return `${environment.pogoApiUrl}`; }
    //
    // private getPropertyUrl(apiKey: string) { return `${PogoApiService.baseUrl}/widget-1?key=${apiKey}`; }
}
