import * as moment from 'moment';
import { MayaArea } from './maya-area.model';

export class MayaSite {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.createdon) this.createdon = moment.utc(json.createdon).toDate();
            if (json.modifiedon) this.modifiedon = moment.utc(json.modifiedon).toDate();
            if (json.tags) this.tags = json.tags.map(area => new MayaArea(area));
        }
    }

    idgroup: string;
    idsite: string;
    idcompany: any;                         // TODO: Determine data type
    idparent_site: any;                     // TODO: Determine data type
    name: string;
    latitude: number;
    longitude: number;
    surface: number;
    createdon: Date;
    modifiedon: Date;
    tz_correction: number;
    status: number;
    tags: MayaArea[] = [];
}
