<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="tid-ezl-dialog">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="td-header-left">
                <img id="i360-title-icon" [src]="headerImgSrc" alt="">
            </div>
            <div id="td-header-right">
                <div class="toro-widget-toolbar-button" (click)="onGotoSite()"><fa-icon icon="external-link-alt"></fa-icon></div>
            </div>
        </div>
    </p-header>

    <div id="toro-bt-dt-modal-content-container">
        <div id="ti-ez-modal-content-wrapper">
            <ng-content></ng-content>
        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
