<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [spinnerText]=""
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu sg-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="false"
            [isMiniModeWidget]="false"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane prevent-select">
                <div id="my-mp-header">
                    {{ selectedCourse }} - {{ selectedArea }}
                </div>
                <div id="my-mp-container">
                    <toro-maya-mini-panel [substance]="substanceN"></toro-maya-mini-panel>
                    <toro-maya-mini-panel [substance]="substanceP"></toro-maya-mini-panel>
                    <toro-maya-mini-panel [substance]="substanceK"></toro-maya-mini-panel>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [spinnerText]=""
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu sg-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-flex-child" id="spec-widget-content">
            <div *ngIf="displayCols == 1" id="spec-grid" class="toro-widget-content-pane one-col" [ngClass]="{'fade-on-resize': isResizing && !isBusy}" style="flex-direction: column;">
                <ng-container [ngTemplateOutlet]="header"></ng-container>
                <ng-container [ngTemplateOutlet]="linearGauges"></ng-container>
            </div>

            <div *ngIf="displayCols > 1" class="toro-widget-content-pane two-col" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <ng-container [ngTemplateOutlet]="header"></ng-container>
                <ng-container [ngTemplateOutlet]="circularGauges"></ng-container>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="tid-title-icon" src="../../../../../assets/images/maya-logo.png" alt="">
</ng-template>

<ng-template #header>
    <div id="my-header-container">
        <p-dropdown [disabled]="coursesSelectList?.length < 2"
                    [options]="coursesSelectList"
                    [(ngModel)]="selectedSite"
                    styleClass="tid-my-dropdown"
        ></p-dropdown>
        <p-dropdown [disabled]="areasSelectList?.length < 2"
                    [options]="areasSelectList"
                    [(ngModel)]="selectedAreaId"
                    styleClass="tid-my-dropdown"
        ></p-dropdown>
    </div>
</ng-template>

<ng-template #circularGauges>
    <div style="flex: 1; display: flex;">
        <toro-maya-circular-gauge
                [substance]="substanceN"
        ></toro-maya-circular-gauge>
        <toro-maya-circular-gauge
                [substance]="substanceP"
        ></toro-maya-circular-gauge>

        <toro-maya-circular-gauge
                [substance]="substanceK"
        ></toro-maya-circular-gauge>
    </div>
</ng-template>

<ng-template #linearGauges>
    <div style="flex: 1; display: flex; flex-direction: column;">
        <toro-maya-linear-gauge [substance]="substanceN"></toro-maya-linear-gauge>
        <toro-maya-linear-gauge [substance]="substanceP"></toro-maya-linear-gauge>
        <toro-maya-linear-gauge [substance]="substanceK"></toro-maya-linear-gauge>
    </div>
</ng-template>
